import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../../../common/constants/Color';
import { LanguageContext } from '../../../../globalContext/LanguageSwitcher';

import {
  successToaster,
  errorToaster,
  getModulePerm,
} from '../../../../utils/helperUtility';

import AuthInput from '../../../../components/auth-input';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../../../assets/icons/left_chevron.svg';
import {
  createAnnouncement,
  editAnnouncement,
  getAnnouncementDetails,
} from '../../../../api/announcement';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../../auth/login/LoginForm.style';

import { addAnnouncementSchema } from '../../../../schema/addAnnouncement';
import ENDPOINTS from '../../../../constants/endpoints';
import { MODULE_CONST } from '../../../../common/constants/ModuleConstant';
import RadioInput from '../../../../components/radio-input';
import CountryWithFLag from '../../../../components/countryList';

import { getCountriesList } from '../../../../api/adminManagement';
const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function AnnouncementForm({ isOpen, onClose, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [countries, setCountries] = useState([]);
  const [pageTitle, setPageTitle] = useState('CREATE_ANNOUNCEMENT');
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const [initialValues, setInitialValues] = useState({
    title: '',
    description: '',
    fk_countries_id: '',
  });

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addAnnouncementSchema,
    onSubmit: async (values) => {
      submitCreateAnnouncement(values);
    },
  });

  const getCountries = async () => {
    const resCountries = await getCountriesList();

    const modifiedCountry = resCountries.data.data.map((country) => {
      return {
        ...country,
        id: country.id,
      };
    });
    setCountries(modifiedCountry);
  };

  useEffect(() => {
    getCountries();
  }, []);

  const submitCreateAnnouncement = async (values) => {
    try {
      setLoading(true);
      let { title, description, fk_countries_id } = values;
      const payload = {
        title,
        description,
      };
      if (fk_countries_id) {
        payload.fk_countries_id = fk_countries_id;
      }
      const response = await createAnnouncement({ ...payload });
      if (response.data.isSuccess) {
        successToaster(t('ANNOUNCEMENT_ADDED_SUCCESS'), language);
        navigate(ENDPOINTS.ANNOUNCEMENT_LIST);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelButton = () => {
    navigate(ENDPOINTS.ANNOUNCEMENT_LIST);
  };

  const handleSubmit = (event) => {
    formik.submitForm(event);
  };

  const getDisableCond = () => {
    return !(formik.isValid && formik.dirty);
  };

  const currentModulePerm = getModulePerm('ANNOUNCEMENT_MANAGEMENT');

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.ANNOUNCEMENT_LIST)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t(pageTitle)}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="title"
            label={t('TITLE')}
            disabled={false}
            required={true}
            sx={textInputField(isFieldError('title'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
            placeholder={t('ENTER_TITLE')}
            name="title"
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('title')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('title') && t(formik.errors.title)}
          />
        </div>

        <div className="row mb-4">
          <CountryWithFLag
            className="col-12 col-md-4"
            placeholderForCountry={t('SELECT_COUNTRY')}
            fieldName="fk_countries_id"
            multiSelect={false}
            labelName={t('Country')}
            options={countries}
            selectedOptions={formik.values.fk_countries_id}
            formik={formik}
            errorText={
              isFieldError('fk_countries_id') &&
              t(formik.errors.fk_countries_id)
            }
            htmlFor="fk_countries_id"
            label={''}
            sx={textInputField(isFieldError('fk_countries_id'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
            placeholder={t('SELECT_COUNTRY')}
            name="fk_countries_id"
            id="fk_countries_id"
            onChange={formik.handleChange}
            value={formik.values.fk_countries_id}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('fk_countries_id')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />
        </div>

        <div className="row">
          <AuthInput
            className="col-12 col-md-8"
            htmlFor="description"
            label={t('DESCRIPTION')}
            disabled={false}
            required={true}
            sx={textInputField(isFieldError('description'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
            placeholder={t('ENTER_DESCRIPTION')}
            name="description"
            id="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('description')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('description') && t(formik.errors.description)
            }
          />
        </div>
        <FormButtons>
          <CancelButton onClick={() => handleCancelButton()}>
            {t('CANCEL')}
          </CancelButton>

          {currentModulePerm.writeMode && (
            <SubmitButton
              type="submit"
              onClick={handleSubmit}
              disabled={getDisableCond()}
            >
              {t('CREATE_ANNOUNCEMENT_BTN')}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>
    </div>
  );
}
