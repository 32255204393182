import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addAnnouncementSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .matches(
      REGEX_PATTERN.ARABIC_ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR_WITH_DOT_IN_END,
      'Special Characters (#, !, ^ etc) and extra spaces are not allowed'
    )
    .required('Field cannot be empty')
    .label('Title'),

  fk_countries_id: Yup.string().label('Country'),

  description: Yup.string()
    .min(3, 'Too Short!')
    .max(500, 'Too Long!')
    .matches(
      REGEX_PATTERN.ARABIC_ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR_WITH_DOT_IN_END,
      'Special Characters (#, !, ^ etc) and extra spaces are not allowed'
    )
    .required('Field cannot be empty')
    .label('description'),
});
