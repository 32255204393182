import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import moment from 'moment';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar-debounce';
import { getAnnouncementList } from '../../api/announcement';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../components/date-range-picker';
import { Colors } from '../../common/constants/Color';
import { getModulePerm, getFormattedDate } from '../../utils/helperUtility';
import { MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';

const CLICK_EVENT_KEYS = {
  NEW_ANNOUNCEMENT: 'NEW_ANNOUNCEMENT',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function AnnouncemenetPage() {
  const { t, i18n } = useTranslation();
  const [announcementData, setAnnouncementData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(10);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value);
  };

  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
  });

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  const getListQueryPayload = () => {
    let dateFilterData = {};
    if (searchTerm) {
      dateFilterData['keyword'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['from'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['to'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    return dateFilterData;
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, filterData]);

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getAnnouncementList({
        ...queryPayload,
        pageLimit: itemsPerPage,
        pageSkip: page - 1,
      });
      setAnnouncementData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;
  const getCountry = (item) => {
    const { nameEng, nameAr } = item;
    if (!nameEng || !nameAr) return t('ALL');
    return isArabic ? nameAr : nameEng;
  };

  const currentModulePerm = getModulePerm('ANNOUNCEMENT_MANAGEMENT');
  const currentItems = announcementData.map((item) => ({
    id: item.id,
    title: item.title || 'NA',
    description: item.description || 'NA',
    createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY, HH:mm'),
    country: getCountry(item),
  }));

  const roleColumns = [
    {
      key: 'title',
      title: t('TH_TITLE'),
      content: (rowData) => <strong>{rowData.name}</strong>,
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'description',
      title: t('DESCRIPTION'),
      requiredStyle: { width: '40%' },
    },

    {
      key: 'createdAt',
      title: t('TH_CREATION_DATE'),
      sortButton: true,
      requiredStyle: { width: '20%%' },
    },

    {
      key: 'country',
      title: t('Country'),
      sortButton: true,
      requiredStyle: { width: '20%' },
    },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_ANNOUNCEMENT:
        navigate(ENDPOINTS.ANNOUNCEMENT_FORM);
        break;
    }
  };

  const handleFilterSelection = (key, event) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: event,
      };

      return data;
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('ANNOUNCEMENT_MANAGEMENT')}
        </Typography>

        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.NEW_ANNOUNCEMENT)}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_ANNOUNCEMENT')}
          </Button>
        )}
      </Stack>

      <Card>
        <StyledFilterBox>
          <div className="w-50 d-flex justify-content-start">
            <div className="ms-2">
              <DateRangePicker
                placeholder={t('DATE_PICKER_CREATION_DATE_TITLE')}
                dateRange={filterData.createdAt}
                popperPlacement="bottom-end"
                setDateRange={(event) =>
                  handleFilterSelection('createdAt', event)
                }
              />
            </div>
          </div>
          <SearchBar
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={t('Search')}
            maxlength={MAX_INPUT_LENGTH}
            onClear={handleClearSearch}
          />
        </StyledFilterBox>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={roleColumns}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          emptyTableText={t('NO_ANNOUNCEMENT_FOUND')}
        />
        <Box
          display="flex"
          justifyContent={'flex-end'}
          sx={userPageStyles.paginationContainerSx}
        >
          <Typography
            color={Colors.LIGHT_GRAY}
            fontSize={12}
            marginY={'6px'}
            fontFamily={'Inter'}
            fontWeight={'500'}
          >
            {t('PG_SHOWING')}
            <Typography
              component={'span'}
              fontSize={12}
              color={Colors.DARK_GRAY}
              fontFamily={'Inter'}
              fontWeight={'500'}
              marginX={'2px'}
            >
              {Math.min(startIndex + 1, totalList)}-
              {Math.min(endIndex, totalList)}
            </Typography>
            {t('PG_FROM')}
            <Typography
              component={'span'}
              fontSize={12}
              color={Colors.DARK_GRAY}
              fontFamily={'Inter'}
              fontWeight={'500'}
              marginX={'2px'}
            >
              {totalList}
            </Typography>
            {t('PG_RESULTS')}
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Card>
    </Container>
  );
}
